<!-- 联盟发起单位介绍 -->
<template>
  <div class="unitintroduce">
    <div v-if="this.language === 'zh_CN'">
      <el-carousel trigger="click" height="650px">
        <el-carousel-item>
          <div>
            <el-image
                class="left-image"
                :src="require('@/assets/images/aboutus/fenghuang.png')"
                fit="contain" />
            <div class="right-info p-scroll">
              <h3>FOHERB GROUP</h3>
              <p>
                &emsp;&emsp;FOHERB GROUP，历经十五年的稳健快速发展，成长为一家专注于研发、生产健康养生产品的全球化
                生物高科技企业，业务辐射欧洲、亚洲、北美、非洲等国际市场。
                <br>
                &emsp;&emsp;FOHERB GROUP秉承“弘扬养生文化、造福人类健康”的企业使命，经过不断摸索与创新，结合西方
                营养学和中医养生理念，以现代生命科学成果为依据，建立了“饮食养生、行为养生、心理养生”三大养
                生学说，为全球用户提供优质的产品、专业的服务和广阔的事业平台，为数以万计家庭的主流消费者带来
                绿色、科技、健康、时尚的品质生活。
                <br>
                &emsp;&emsp;国医大师张大宁教授是FOHERB GROUP全球健康推广顾问、研究院名誉院长和首席专家，作为中医肾
                病学奠基人、国际欧亚科学院院士、权威的国家领导人保健专家，在全球享有盛誉。并与全球知名院校、科
                研机构强强联合，开展科研合作，搭建产、学、研战略合作平台，不断提高技术创新水平，为企业的发展注
                入新的活力。
                <br>
                &emsp;&emsp;Fohow （Tianjin）Pharmaceutical Co.,Ltd.是foherb group的下属企业，产品囊括了健康食品、保健器
                材、保洁用品、化妆品、小型厨具、家用电器六大系列。生产基地采用现代化设备，30万级无菌车间、全封
                闭灌装车间、无尘净化车间等，每道工序都完全按照国际质量标准和先进工艺流程执行，保证了所有产品在
                各制造阶段的整体品质。
                <br>
                &emsp;&emsp;Fohow （Tianjin）Pharmaceutical Co.,Ltd.投资建设了多条现代化全自动生产线，以科学严谨的管理流
                程，保障每道工序符合严格的国际质量标准，确保产品的全周期品质安全。通过了世界上数十个国家和地区
                众多严格的产品检测和认证，并取得了食品和药品GMP、ISO9000、HACCP、FDA、清真认证和纯犹太食
                品认证等国际认证。从2007年起长期对欧、亚、非等多个区域提供优质产品。
                多年来，秉承“大爱无疆，仁行天下”的慈善宗旨，积极参与海内外赈灾救助、捐资助学、扶贫济困、
                关爱孤儿等公益事业，践行企业公民责任，在全球留下了一串串爱的足迹，帮助了上万名孤儿和残障儿童，
                得到各国政府和社会各界的广泛认可与支持。
                <br>
                &emsp;&emsp;荣获世界经济论坛新领军企业会员单位、防癌抗癌总裁圆桌会成员单位、亚洲品牌理事会会员单位、中
                国国际投资促进会副会长单位、中国商业股份制企业经济联合会副会长单位、中国商业经济学会健康产业委
                员会秘书长单位。
              </p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <el-image
                class="left-image"
                :src="require('@/assets/images/aboutus/ea.png')"
                fit="contain" />
            <div class="right-info p-scroll">
              <H3>欧亚大健康经济发展联盟</H3>
              <p>
                &emsp;&emsp;欧亚大健康经济发展联盟注册于俄罗斯，是一个横跨欧亚大陆，立足大健康产业的国际化、多元化的服务
                平台。联盟在“一带一路”互联互通的大背景下成立，致力于促进‘一带一路’沿线各国大健康及相关产业的
                对接与合作、需求与消费、投资与就业，增进沿线各国人民的健康指数，并将不同企业的优秀产品和服务传播
                发展到欧亚各国，为欧亚各族人民造福。
                <br>
                &emsp;&emsp; 欧亚大健康经济发展联盟下设商学院、商务部、报检部、电商部、市场部等机构，并在俄罗斯、中国、乌
                克兰、吉尔吉斯斯坦、蒙古、哈萨克斯坦等国家建立分支机构，为大健康行业企业发展提供发展平台。
                <br>
                &emsp;&emsp;欧亚大健康经济发展联盟能够很好的帮助企业连接适合的有效资源，避免企业在跨国间的无效投入，帮助
                企业在国际市场落地生根，并通过其特有的资源为各成员赋能背书。
                <br>
                &emsp;&emsp;目前，欧亚大健康经济发展联盟开展以下服务：国际线下货架展示，国际线下会议推广、国际电视台广告、
                国际户外广告、国际名人明星背书、国际网红视频（模特）、商城上架、产品报关通关、个人及产品IP背书 、
                协助建立分公司等。
              </p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-if="this.language !== 'zh_CN'">
      <el-carousel trigger="click" height="650px" :autoplay="false">
        <el-carousel-item>
          <div>
            <el-image
                class="left-image"
                :src="require('@/assets/images/aboutus/fenghuang.png')"
                fit="contain" />
            <div class="right-info p-scroll">
              <h3>Корпорация FOHERB</h3>
              <p>
                &emsp;&emsp;После 15 лет быстрого и устойчивого развития корпорация FOHERB стала крупным биотехнологическим предприятием, которое занимается разработкой, производством и продажей медицинских услуг и товаров для здоровья. Деятельность компании охватывает такие сферы, как индустрия здравоохранения, электронная коммерция, биотехнологические исследования и разработки, образование и др.  во многих странах Европы, Азии, Северной Америки, Африки.
                <br>
                &emsp;&emsp;Корпоративной миссией FOHERB является развитие и пропаганда культуры здорового образа жизни и стремление приносить пользу каждому человеку. Благодаря многолетним исследованиям и успешному применению инноваций компания разработала «Три доктрины здоровья: здоровое питание, здоровое поведение и здоровая психика». Эти доктрины основаны на сочетании главных принципов диетологии стран Запада и китайской традиционной медицины, а также на последних достижениях современной науки. Корпорация FOHERB стремится предоставить своим клиентам по всему миру высококачественную продукцию, профессиональные услуги и специализированную бизнес-платформу для взаимодействия, благодаря чему тысячи семей смогут улучшить качество своей жизни.
                <br>
                &emsp;&emsp;Специалист корпорации FOHERB, профессор Чжан Данин, мастер традиционной китайской медицины, является международным консультантом по продвижению здорового образа жизни, а также сотрудничает со всемирно известными институтами и научно-исследовательскими центрами для создания единой площадки, где объединяются производство, обучение и исследования.
              </p>
              <h3>Тяньцзиньская фармацевтическая компания Fohow （Tianjin）Pharmaceutical Co.,Ltd.</h3>
              <p>
                &emsp;&emsp;Fohow (Tianjin) Pharmaceutical Co., Ltd., дочерняя компания корпорации Foherb, выпускает высококачественные пищевые продукты, медицинские приборы, моющие средства, косметику, кухонную посуду, бытовую технику и другие товары. Технико-производственная база предприятия включает современное оборудование, стерильный цех, герметичный цех, цех беспылевой очистки и др. Для обеспечения качества продукции каждый производственный процесс организован в соответствии с международными стандартами качества и передовыми технологиями.
                <br>
                &emsp;&emsp;Компания Fohow (Tianjin) Pharmaceutical Co., Ltd., стремится инвестировать в строительство полностью автоматизированных линий производства и практикует строгий научный подход к процессу управления, все это несомненно гарантирует качество и безопасность продукции на протяжении всего цикла производства. Продукция компании успешно прошла необходимые испытания и сертификацию во многих странах мира. Продукция компании обладает такими сертификатами, как GMP для пищевых продуктов и лекарств, ISO9000 на соответствие международным стандартам, HACCP, «Сертификат фармацевтического продукта» FDA США, Сертификаты Кошерности и Халяль. С 2007 года продукция компании поставляется в Европу, Азию, Африку и другие регионы.
                <br>
                &emsp;&emsp;На протяжении многих лет компания Fohow (Tianjin) Pharmaceutical Co., Ltd., занимается корпоративной благотворительностью, активно участвует в таких благотворительных мероприятиях, как помощь при стихийных бедствиях, оказание материальной и финансовой помощи, оказание адресной социальной помощи нуждающимся, поддержка и забота о детях-сиротах и другое. Благодаря своей высокой социальной ответственности компания Fohow получила широкое признание во многих странах мира.
                <br>
                &emsp;&emsp;Компания Fohow (Tianjin) Pharmaceutical Co., Ltd., является участником Всемирного экономического форума, членом Круглого стола по борьбе с раком, членом Азиатского совета по брендам, заместителем председателя Китайского совета по международным инвестициям, заместителем председателя Китайского союза акционерных обществ, председателем Китайского научного сообщества по изучению бизнеса и экономики.
              </p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <el-image
                class="left-image"
                :src="require('@/assets/images/aboutus/ea.png')"
                fit="contain" />
            <div class="right-info p-scroll">
              <h3>Евразийская Ассоциация «Здоровье и Процветание»</h3>
              <p>
                &emsp;&emsp;ЕВРАЗИЙСКАЯ АССОЦИАЦИЯ «ЗДОРОВЬЕ И ПРОЦВЕТАНИЕ» представляет собой сервисную платформу для взаимодействия в сфере здравоохранения в странах Европы и Азии. Ассоциация была создана в контексте концепции «Один пояс, один путь», ее деятельность, главным образом, направлена на развитие межотраслевого сотрудничества и увеличение потребительского спроса, на стимулирование инвестиционной активности и роста занятости населения в странах Евроазиатского региона.
                <br>
                &emsp;&emsp;Организационная структура ЕВРАЗИЙСКОЙ АССОЦИАЦИИ «ЗДОРОВЬЕ И ПРОЦВЕТАНИЕ» состоит из бизнес-школы, отдела  коммерции, отдела экспертизы, отдела электронной торговли, отдела маркетинга и другие. Имеются филиалы в России, Китае, Украине, Киргизии, Монголии, Казахстане и других странах. Ассоциация успешно продвигает интересы компаний, помогает в поиске эффективных ресурсов, оказывает поддержку инвестиционной деятельности и сопровождение инвестиционных проектов, а также помогает выйти на международные рынки. В настоящее время ЕВРАЗИЙСКАЯ АССОЦИАЦИЯ «ЗДОРОВЬЕ И ПРОЦВЕТАНИЕ» предоставляет следующие услуги: международные офлайн-продажи, международный консалтинг, проведение международных конференций, международная тв-реклама, международная наружная реклама, реклама с участием мировых звезд и блоггеров, таможенное оформление товаров, PR-менеджмент, продвижение товаров и  услуг, помощь в открытии филиалов и т. д.
              </p>
              <h3>Компания «Приоритет-Здоровье»</h3>
              <p>
                &emsp;&emsp;Компания «Приоритет-Здоровье» является одним из ведущих партнеров Международной и Евразийской Ассоциаций «Здоровье и Процветание». Деятельность компании направлена на популяризацию знаний и техник традиционной китайской медицины, а также на развитие культуры здорового образа жизни.
                <br>
                &emsp;&emsp;Компания «Приоритет-здоровье», как официальный представитель крупнейших компаний-производителей, поставляет медицинское оборудование, высококачественные товары для здоровья, косметику и услуги в страны Среднеазиатского региона. При изготовлении нашей продукции применяется секретная рецептура китайской традиционной медицины, история которой насчитывает тысячи лет. Наша продукция поможет Вам сохранить красоту, молодость и здоровье. Качество продукции подтверждено множеством сертификатов соответствия, действующих на территории Таможенного Союза. Некоторая продукция сертифицирована в Европе и США.
                <br>
                &emsp;&emsp;Благодаря многолетнему опыту работы на Евроазиатском рынке компания заслужила уважение клиентов в разных уголках мира. Компания имеет филиалы в Казахстане, Таджикистане, Монголии и Кыргызстане.
              </p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  export default {
    name: "UnitIntroduce",
    computed:{
      ...mapGetters([
        'language'
      ])
    }
  }
</script>

<style scoped lang="scss">
.left-image{
  width:30rem;
  height: 20rem;
  float: left;
  margin: 0 1rem;
}
.right-info {
  text-align: left;
  h3 {
    margin: 0;
    padding: 0 1rem;
  }
  p {
    line-height: 1.1rem;
    padding: 0 1rem;
  }
}
.el-carousel__item:nth-child(n) {
  background-color: #efeeee;
}
@media screen and (max-width: 991px){
  .left-image{
    width: 260px;
    height: 260px;
    margin: 0 1rem;
    overflow: hidden;
  }
  .p-scroll {
    width: 100%;
    height: 500px;
    overflow: scroll;
  }
  .right-info {
    p {
      padding-bottom: 120px;
    }
  }
}
</style>
